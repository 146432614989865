var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":_vm.load,"rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"flex-wrap text-initial",staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","padding":"0.5rem"}},[_c('div',{staticStyle:{"display":"inline-block","font-weight":"bold"}},[_c('feather-icon',{attrs:{"icon":_vm.icon ? _vm.icon : 'DatabaseIcon',"size":"20"}}),_vm._v(" "+_vm._s(_vm.name)+" "),_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.getStatus(_vm.status).color))}},[_vm._v(" "+_vm._s(_vm.getStatus(_vm.status).name)+" ")]),_vm._t("status")],2),(!_vm.noAction)?_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.layout.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,false,109482823)},[_vm._t("action")],2):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('table',{staticClass:"table table-striped custom_table text_nobreak"},[_c('tbody',{staticStyle:{"text-align":"initial"}},[_vm._t("other_data"),_vm._l((_vm.item),function(item,key){return _c('tr',{key:key},[_c('th',{attrs:{"scope":"row","width":_vm.big_th ? '26%' : '20%'}},[_vm._v(_vm._s(_vm.subtitle ? item.key : _vm.$t(("Global." + (item.key)))))]),_c('td',{attrs:{"width":'80%'}},[(typeof item.value === 'string' && item.value.search(/\n/g) > 0)?_c('span'):(
                          item.key &&
                          (item.key.includes('date') ||
                            item.key.includes('close_at') ||
                            item.key.includes('ends_at') ||
                            item.key.includes('starts') ||
                            item.key.includes('expiration') ||
                            item.key.includes('start') ||
                            item.key.includes('end') ||
                            item.key.includes('created_at') ||
                            item.key.includes('updated_at') ||
                            item.key === 'from' ||
                            item.key === 'to')
                        )?_c('span',[_c('span',[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(item.value)+" ")])]):_c('span',[_vm._v(" "+_vm._s(Array.isArray(item.value) || item.value == true || item.value == false ? _vm.$t(("Global." + (item.value))) : item.value)+" ")])])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item && _vm.item.length == 0 || !_vm.item),expression:"item && item.length == 0 || !item"}],staticClass:"w-100 text-center py-2"},[_vm._v(" No Data Found ")]),_vm._t("bottom_other_data")],2)])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }